<template>
  <div class="debug__item">
    <el-popover v-model="showPopover" placement="top">
      <p class="debug__text-success font--small m-b-small m-t-small" :class="{ 'is-visible': copySuccess }">
        {{ $t('TEXT_COPIED_SUCCESSFULLY') }}
      </p>

      <input :class="'debug__input-copy-' + data.Xnodekey" class="debug__input-copy" v-model="data.Xnodekey" />

      <div class="text-right no-margin">
        <el-button type="text" size="mini" @click="showPopover = false">{{ $t('CANCEL') }}</el-button>
        <el-button type="text" size="mini" @click="onClickCopy(data.Xnodekey)">{{ $t('COPY') }}</el-button>
      </div>

      <el-button slot="reference" class="debug__btn-popover">
        <span class="debug__text">{{ data.XcontrolChild }}</span>
      </el-button>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'DebugItem',

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      copySuccess: false,
      showPopover: false
    };
  },

  methods: {
    onClickCopy(item) {
      const copyText = document.querySelector('.debug__input-copy-' + item);
      copyText.select();
      document.execCommand('copy');
      this.copySuccess = true;

      setTimeout(() => {
        this.copySuccess = false;
      }, 5000);
    }
  }
};
</script>

<style lang="scss"></style>
