<template>
  <div class="list-main__item-actions">
    <!-- <el-button
            v-if="data.PdfOnPath"
            icon="el-icon-document"
            type="text"
            @click.prevent="onClickReport(data)">
    </el-button>-->
    <a
      :href="getDocumentHandlerSrc(data.PdfOnPath)"
      v-if="data.PdfOnPath && showReport"
      class="el-button font--regular el-button--text"
      target="_blank"
    >
      <i class="el-icon-document font--regular-plus"></i>
    </a>

    <el-button
      v-if="data.InfoTooltipNumber && showTooltip"
      icon="el-icon-info"
      class="font--regular-plus"
      type="text"
      @click.prevent="onClickTooltip(data)"
    ></el-button>
  </div>
</template>

<script>
import mixins from '@/shared/mixins/index';

export default {
  name: 'ListItem',

  mixins: [mixins],

  props: {
    data: {
      type: Object,
      required: true
    },

    showReport: {
      type: Boolean,
      required: false,
      default: true
    },

    showTooltip: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  methods: {
    onClickReport(node) {
      let url = this.getDocumentHandlerSrc(node.PdfOnPath);

      this.$http.get(url).then(() => {});
    },

    onClickTooltip(node) {
      this.$store.commit('dialog/OPEN_DIALOG', {
        loading: true
      });

      this.$store
        .dispatch('GET_TOOLTIP', node)
        .then(response => {
          this.$store.commit('dialog/OPEN_DIALOG', {
            content: response.data.RESPONSE,
            loading: false
          });
        })
        .catch(() => {
          this.$store.commit('dialog/OPEN_DIALOG', {
            loading: false
          });
        });
    }
  }
};
</script>
