<template>
  <li class="list-main__item list__item--bordered">
    <a
      :href="getDocumentHandlerSrc(data.PdfOnPath)"
      v-if="data.PdfOnPath"
      class="el-button font--regular el-button--text wrap text-left"
      target="_blank"
    >
      <i class="el-icon-document m-r-small"></i>
      <span>{{ data.Lanqkey01 }}</span>
    </a>

    <!-- Actions -->
    <div class="flex a-right" v-if="data.PdfOnPath || data.InfoTooltipNumber">
      <ListItemActions :data="data" :show-report="false"></ListItemActions>
    </div>

    <!-- Debug -->
    <DebugItem v-if="debug.visible" :data="data"></DebugItem>
  </li>
</template>

<script>
import DebugItem from '@/components/DebugItem';
import ListItemActions from '@/components/List/ListItemActions';
import mixins from '@/shared/mixins/index';

export default {
  name: 'ANZDOWNLOAD',

  mixins: [mixins],

  components: {
    DebugItem,
    ListItemActions
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss">
.list-main__item {
  > .el-button--text {
    padding-right: 1rem;
    padding-left: 1rem;
    line-height: 1.3rem;
    width: 100%;

    @media screen and (max-width: $screen-md) {
      display: flex;
    }
  }
}
</style>
